//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GeneralEditWithTree from '@/components/GeneralEditWithTree';
import GeneralEditTree from '@/components/GeneralEditTree';
export default {
  name: 'Index',
  components: {
    GeneralEditWithTree: GeneralEditWithTree,
    GeneralEditTree: GeneralEditTree
  },
  data: function data() {
    return {
      id: this.$route.params.id,
      rules: {}
    };
  },
  computed: {
    url: function url() {
      if (this.$route.params.id) {
        return {
          getUrl: '/city',
          method: 'put'
        };
      } else {
        return {
          getUrl: '/city',
          method: 'post'
        };
      }
    }
  },
  methods: {
    handleDataChanged: function handleDataChanged() {
      this.$refs.GeneralEditTree.getTreeData();
    },
    handleNodeClick: function handleNodeClick(id) {
      this.$refs.GeneralEditWithTree.initData(id);
    }
  }
};